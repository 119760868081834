import { SVGProps } from 'react';

function PersonalLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={185}
      height={32}
      viewBox="0 0 185 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_778_21673)">
        <path
          d="M7.5863 6.87991C7.54879 7.19859 4.4355 29.1506 4.32297 29.9379C4.24795 30.7346 4.65118 31.0627 5.14818 31.1564H9.41488C9.93064 31.1564 10.5871 30.894 10.7934 30.0598C11.4592 25.2888 14.0661 6.56122 14.1129 6.29877C14.1598 5.98945 14.2349 5.79262 14.5068 5.61453L22.8058 0H0.365723C-0.00937229 0 -0.140655 0.553017 0.178175 0.806093C0.309458 0.899824 4.73558 3.90861 6.75171 5.23023C7.55817 5.74575 7.68007 6.1488 7.5863 6.87991Z"
          fill="url(#paint0_linear_778_21673)"
        />
        <path
          d="M24.775 0.637294C24.8219 0.599801 24.8876 0.590428 24.9438 0.581055C25.0001 0.581055 25.0564 0.590428 25.1126 0.609174C25.1126 0.609174 25.5909 0.796638 25.8253 0.890369L26.6505 1.30279C27.1381 1.57461 27.5883 1.8933 28.0009 2.25885C28.6854 2.87748 29.2574 3.60859 29.7075 4.42405C29.9795 4.91146 30.1952 5.42698 30.364 5.96125C30.364 5.96125 30.4484 6.23308 30.514 6.5049C31.5361 10.5916 29.0793 13.9847 28.6385 14.5939C27.3257 16.403 25.4971 17.7621 23.3778 18.4932L23.0215 18.6244C23.0215 18.6244 27.4007 28.7381 27.7102 29.4129C28.0102 30.2846 27.2225 31.1188 26.6599 31.1188H21.8118C21.5305 31.1188 21.2773 30.9501 21.1648 30.6877C21.146 30.6408 14.5162 15.4375 14.4693 15.3344C14.4599 15.2688 14.4599 15.2032 14.4693 15.1376C14.4693 15.1095 14.6194 14.0128 14.6756 13.6098C14.685 13.5254 14.7694 13.4598 14.8538 13.4692C15.5477 13.4692 18.2859 13.4785 18.3515 13.4785C18.4922 13.4879 19.2893 13.4598 19.2893 13.4598C20.8741 13.4036 21.8868 12.8693 22.3557 12.5693C22.6089 12.41 22.8527 12.2225 23.0684 12.0069C23.706 11.3602 24.0999 10.5166 24.203 9.60742C24.278 9.10127 24.2312 8.58574 24.0624 8.10771C23.9873 7.89213 23.8748 7.67654 23.7529 7.48908C23.4247 7.00168 22.9652 6.61738 22.4213 6.38305C22.2432 6.30806 22.065 6.25182 21.8868 6.19558C21.568 6.10185 21.2492 6.01749 20.9303 5.96125L17.0856 5.93313L24.775 0.637294Z"
          fill="url(#paint1_linear_778_21673)"
        />
        <path
          d="M52.035 25.3919C51.2942 24.5951 50.9285 23.6203 50.9285 22.4768V11.4633H47.9746L48.2559 8.99818H50.9285V4.74276L54.098 4.42407V8.99818H58.6367V11.4633H54.098V20.5366C54.098 21.7363 54.1731 22.5518 54.3137 23.0017C54.4356 23.4329 54.7826 23.7609 55.2139 23.864C55.6734 23.9953 56.508 24.0609 57.7271 24.0609H58.5335L58.2897 26.5916H55.439C53.9011 26.5822 52.7758 26.1886 52.035 25.3919Z"
          fill="#2E4865"
        />
        <path
          d="M65.5666 25.4107C64.2913 24.6421 63.2691 23.5267 62.6127 22.1957C61.9282 20.8366 61.5906 19.3088 61.5906 17.6216C61.5906 15.9344 61.9282 14.4066 62.6127 13.0475C63.2691 11.7165 64.2913 10.5917 65.5666 9.82311C66.8607 9.03576 68.3798 8.64209 70.1427 8.64209C71.8776 8.64209 73.3967 9.03576 74.7001 9.82311C75.9848 10.5823 77.0163 11.7071 77.6728 13.0381C78.3573 14.3972 78.6949 15.925 78.6949 17.6122C78.6949 19.2994 78.3573 20.8272 77.6728 22.1863C77.0163 23.5267 75.9755 24.6421 74.7001 25.4013C73.3967 26.1887 71.8776 26.5823 70.1427 26.5823C68.3798 26.5917 66.8513 26.198 65.5666 25.4107ZM74.0625 22.1582C74.9908 21.0803 75.4503 19.5712 75.4503 17.6216C75.4503 15.672 74.9908 14.1629 74.0531 13.085C73.1247 12.0071 71.8213 11.4634 70.1334 11.4634C68.4454 11.4634 67.1326 11.9977 66.2136 13.085C65.2946 14.1723 64.8258 15.672 64.8258 17.6216C64.8258 19.5712 65.2853 21.0803 66.2136 22.1582C67.142 23.2361 68.4454 23.7798 70.1334 23.7798C71.8307 23.7704 73.1341 23.2361 74.0625 22.1582Z"
          fill="#2E4865"
        />
        <path
          d="M104.379 8.99827H106.986L107.371 11.8477C108.262 10.7417 109.115 9.93559 109.922 9.42006C110.681 8.92329 111.563 8.65146 112.472 8.64209C112.744 8.64209 113.016 8.67958 113.279 8.7452L112.96 12.232C112.613 12.157 112.256 12.1195 111.9 12.1289C111.047 12.1289 110.203 12.3257 109.434 12.71C108.665 13.0943 108.102 13.5443 107.746 14.0598V26.2262H104.37L104.379 8.99827Z"
          fill="#2E4865"
        />
        <path
          d="M117.292 24.5109C116.092 23.1236 115.501 21.3334 115.501 19.1307C115.492 17.3404 115.904 15.5782 116.701 13.9754C117.461 12.4195 118.614 11.0791 120.04 10.1043C121.475 9.1295 123.087 8.64209 124.897 8.64209C126.538 8.64209 127.804 9.04514 128.695 9.84186C129.586 10.6386 130.036 11.7071 130.036 13.0475C130.036 14.0223 129.773 14.969 129.276 15.8126C128.77 16.6936 127.926 17.4154 126.726 17.9965C125.526 18.5683 123.96 18.8588 122.009 18.8588C121.446 18.8588 120.865 18.8307 120.265 18.7745C119.805 18.737 119.355 18.6714 118.914 18.5776C118.914 20.4991 119.308 21.8489 120.096 22.6269C120.884 23.4048 121.981 23.7891 123.388 23.7891C124.185 23.7798 124.982 23.6954 125.76 23.5267C126.576 23.3673 127.373 23.1143 128.132 22.7675L127.992 25.2607C126.445 26.1512 124.625 26.6011 122.534 26.6011C120.237 26.5823 118.493 25.8981 117.292 24.5109ZM125.844 15.7188C126.688 15.0627 127.11 14.3035 127.11 13.4318C127.11 12.7757 126.876 12.2789 126.407 11.9508C125.938 11.6228 125.329 11.454 124.578 11.454C123.453 11.454 122.431 11.8571 121.521 12.6632C120.612 13.4693 119.852 14.6972 119.271 16.3375C119.974 16.5718 120.799 16.6936 121.737 16.6936C123.631 16.703 125 16.375 125.844 15.7188Z"
          fill="#2E4865"
        />
        <path
          d="M161.178 16.075V26.2356H158.009V16.1032C158.009 14.4816 157.69 13.31 157.062 12.5695C156.424 11.829 155.392 11.4634 153.967 11.4634C150.919 11.4634 149.391 13.0569 149.391 16.2438V26.2356H146.221V16.7405C146.221 14.7722 145.874 13.4037 145.181 12.6257C144.487 11.8477 143.483 11.4634 142.17 11.4634C141.139 11.4634 140.22 11.6509 139.423 12.0258C138.645 12.382 138.007 12.9725 137.594 13.713V26.2356H134.425V8.9983H136.891L137.247 10.8261C138.119 10.0481 138.991 9.49508 139.864 9.15764C140.764 8.81084 141.73 8.64212 142.696 8.65149C145.321 8.65149 147.178 9.80439 148.256 12.1008C149.457 9.80439 151.548 8.65149 154.549 8.65149C158.965 8.65149 161.178 11.126 161.178 16.075Z"
          fill="#2E4865"
        />
        <path
          d="M166.702 1.6123H169.871V5.12724H166.702V1.6123ZM166.702 8.99836H169.871V26.2356H166.702V8.99836Z"
          fill="#2E4865"
        />
        <path
          d="M178.114 25.3919C177.373 24.5951 177.007 23.6203 177.007 22.4768V11.4633H174.053L174.335 8.99818H177.007V4.74276L180.177 4.42407V8.99818H184.716V11.4633H180.177V20.5366C180.177 21.7363 180.252 22.5518 180.393 23.0017C180.514 23.4329 180.861 23.7609 181.293 23.864C181.752 23.9953 182.587 24.0609 183.806 24.0609H184.612L184.369 26.5916H181.518C179.989 26.5822 178.855 26.1886 178.114 25.3919Z"
          fill="#2E4865"
        />
        <path
          d="M86.5344 31.9906V24.1265C87.0971 24.9232 87.8754 25.5324 88.86 25.9542C89.9009 26.3854 91.0074 26.601 92.1327 26.5916C93.755 26.5916 95.1241 26.1979 96.2494 25.4106C97.3747 24.6232 98.228 23.5547 98.8 22.2143C99.372 20.8646 99.6627 19.3368 99.6627 17.6215C99.6627 15.9343 99.3533 14.4065 98.7438 13.0474C98.1342 11.6883 97.2621 10.6104 96.1181 9.83239C94.9834 9.04504 93.6518 8.65137 92.1233 8.65137C89.6102 8.65137 87.6316 9.56994 86.1781 11.3977L85.8217 9.00755H83.3649V31.9999L86.5344 31.9906ZM87.8191 13.0474C88.7944 11.9882 90.0322 11.4633 91.5326 11.4633C93.0611 11.4633 94.252 12.007 95.1241 13.0942C95.9962 14.1815 96.4275 15.6906 96.4275 17.6121C96.4275 19.5336 95.9962 21.0427 95.1241 22.13C94.252 23.2173 93.0611 23.7609 91.5326 23.7703C90.004 23.7703 88.7662 23.2641 87.8004 22.2425C86.8345 21.2208 86.3563 19.6836 86.3563 17.6121C86.3656 15.625 86.8439 14.1066 87.8191 13.0474Z"
          fill="#2E4865"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_778_21673"
          x1={-1.12231}
          y1={28.022}
          x2={15.4029}
          y2={-4.42451}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18A4E9" />
          <stop offset={1} stopColor="#06E4AE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_778_21673"
          x1={13.5301}
          y1={35.4842}
          x2={30.0554}
          y2={3.03772}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18A4E9" />
          <stop offset={1} stopColor="#06E4AE" />
        </linearGradient>
        <clipPath id="clip0_778_21673">
          <rect width={184.715} height={32} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PersonalLogo;
